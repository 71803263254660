import { Injectable } from '@angular/core';
import {
  HashMap,
  TranslateParams,
  TranslocoScope,
  translate,
} from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { BaseLanguageService } from './base-language.service';

@Injectable({ providedIn: 'root' })
export class TranslateService extends BaseLanguageService {
  translateInstant(key: string, params?: { [key: string]: any }): string {
    return translate(key);
  }

  selectTranslate<T = any>(
    key: TranslateParams,
    params?: HashMap | undefined,
    lang?: TranslocoScope | TranslocoScope[],
    _isObject?: boolean | undefined
  ): Observable<T> {
    return this.translocoService.selectTranslate(key, params, lang, _isObject);
  }
}
