import { Injectable, inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from './translate.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor() {}
  private toastr = inject(ToastrService);
  private translateService = inject(TranslateService);

  success(
    message: string,
    title: string = this.translateService.translateInstant('vlc:Admin:Toastr.Success')
  ) {
    this.toastr.success(message, title);
  }

  error(
    message: string,
    title: string = this.translateService.translateInstant('vlc:Admin:Toastr.Fail')
  ) {
    this.toastr.error(message, title);
  }

  info(
    message: string,
    title: string = this.translateService.translateInstant('vlc:Admin:Toastr.Info')
  ) {
    this.toastr.info(message, title);
  }

  warning(
    message: string,
    title: string = this.translateService.translateInstant('vlc:Admin:Toastr.Warning')
  ) {
    this.toastr.warning(message, title);
  }
}
