import { Injectable } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeTr from '@angular/common/locales/tr';
import localeEn from '@angular/common/locales/en';
import localeDe from '@angular/common/locales/de';
import localeFr from '@angular/common/locales/fr';
import localeRu from '@angular/common/locales/ru';
import localeEs from '@angular/common/locales/es';
import localeFa from '@angular/common/locales/fa';
import localeKo from '@angular/common/locales/ko';
import localeRo from '@angular/common/locales/ro';
import localeSk from '@angular/common/locales/sk';
import localeZh from '@angular/common/locales/zh';

@Injectable({ providedIn: 'root' })
export class RegionalService {
  public _locale: string = '';

  constructor() {}

  registerCulture(culture: string) {
    this._locale = culture;
    switch (culture) {
      case 'tr-TR': {
        registerLocaleData(localeTr, 'tr-TR');
        break;
      }
      case 'en-US': {
        registerLocaleData(localeEn, 'en-US');
        break;
      }
      case 'de-DE': {
        registerLocaleData(localeDe);
        break;
      }
      case 'fr-FR': {
        registerLocaleData(localeFr);
        break;
      }
      case 'ru-RU': {
        registerLocaleData(localeRu);
        break;
      }
      case 'es-ES': {
        registerLocaleData(localeEs, 'es-ES');
        break;
      }
      case 'fa-IR': {
        registerLocaleData(localeFa, 'fa-IR');
        break;
      }
      case 'ko-KR': {
        registerLocaleData(localeKo, 'ko-KR');
        break;
      }
      case 'ro-RO': {
        registerLocaleData(localeRo, 'ro-RO');
        break;
      }
      case 'sk-SK': {
        registerLocaleData(localeSk, 'sk-SK');
        break;
      }
      case 'zh-CN': {
        registerLocaleData(localeZh, 'zh-CN');
        break;
      }
    }
  }
}
