import { Translation, TranslocoService } from '@ngneat/transloco';
import { inject } from '@angular/core';
import { firstValueFrom } from 'rxjs';

export class BaseLanguageService {
  public translocoService = inject(TranslocoService);

  load(lang: string): Promise<Translation> {
    return firstValueFrom(this.translocoService.load(lang));
  }

  async setCurrentLanguage(language: string): Promise<Translation> {
    this.setLanguage(language);
    return await this.load(language);
  }

  setLanguage(lang: string): void {
    this.translocoService.setActiveLang(lang);
  }

  getLanguage(): string {
    return this.translocoService.getActiveLang();
  }
}
