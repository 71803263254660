import { Injectable } from '@angular/core';
import { AvailableLangs, Translation, getBrowserLang } from '@ngneat/transloco';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseLanguageService } from './base-language.service';

@Injectable({
  providedIn: 'root',
})
export default class LanguageService extends BaseLanguageService {
  private readonly LANG_KEY = 'selectedLanguage';

  onLanguageChange$ = new BehaviorSubject('');

  init(): Promise<Translation> {
    let langCode = localStorage.getItem('selectedLanguage');

    if (!langCode) {
      langCode = navigator.language === 'tr' ? 'tr-TR' : 'en-US';
    }

    console.log('langCode', langCode);
    this.saveLanguageToLocalStorage(langCode);
    return this.setCurrentLanguage(langCode);
  }

  saveLanguageToLocalStorage(language: string) {
    localStorage.setItem('selectedLanguage', language);
  }

  getLanguageFromLocalStorage(): string | null {
    return localStorage.getItem('selectedLanguage');
  }

  getActiveLanguage(): string {
    return this.translocoService.getActiveLang();
  }

  getAvailableLanguages(): AvailableLangs {
    return this.translocoService.getAvailableLangs();
  }

  watchLanguageChanges(): Observable<string> {
    return this.translocoService.langChanges$;
  }

  getBrowserLanguage(): string | undefined {
    return getBrowserLang();
  }

  override async setCurrentLanguage(language: string): Promise<Translation> {
    const result = super.setCurrentLanguage(language);
    this.onLanguageChange$.next(language);
    return result;
  }
}
